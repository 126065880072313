:root {
    --swiper-image-ratio: 33.3%;
    /* ratio 16:9 = 56.25% */

    --swiper-width: 50%;
    --swiper-inactive-scale: .85;
    /* makes the other slide smaller */

    /* responsive settings */
    --swiper-mobile-width: 90%;
    --swiper-mobile-inactive-scale: .95;
}

/* common */
.service .section-3 svg {
    height: 100px;
    width: 100px;
}

.services-pen {
    position: absolute;
    right: 50px;
    top: -33px;
    height: 355px;
    width: 305px;
}

.technology_software .services-pen {
    top: -162px;
    right: 80px;
    height: 323.15px;
    width: 86.64px;
}

.technology .services-pen {
    position: absolute;
    right: 50px;
    top: -33px;
    height: 303px;
    width: 250px;
}

@media only screen and (max-width: 991px) {
    .technology .services-pen {
        height: 255px;
        width: 200px;
    }
}

@media only screen and (max-width: 768px) {
    .services-pen {
        width: 108px;
        height: 130px;
        right: 20px;
    }

    .service .section-3 svg {
        height: 50px;
        width: 50px;
    }

    .technology_software .services-pen {
        height: 174px;
        top: -87px;
        right: 20px;
    }

    .technology .services-pen {
        right: 20px;
        width: 108px;
        height: 170px;
    }

    .services h3 {
        font-size: 18px;
    }

    .service-section-title {
        font-size: 16px;
    }
}

/* .service .swiper-wrapper {
    align-items: center;
}

.service .swiper-slide-visible {
    display: none !important;
}

.service .swiper-slide-prev, .service .swiper-slide-next {
    transform: scale(0.5) !important;
    position: relative;
    display: block !important;
}

.service .swiper-slide-prev {
    left: 215px;
    z-index: 1;
}

.service .swiper-slide-next {
    right: 215px;
    z-index: 1;
}

.service .swiper-slide-active {
    transform: scale(1);
    z-index: -1 !important;
    display: block !important;
} */



/* .service .swiper-slide {
    display: none;
}
.service .swiper-slide-visible {
    display: block;
} */
/* common */


/* ******* */
/* service */
/* ******* */

.service .section-1 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00597B;
}

.technology .section-1 svg {
    height: 400px;
    width: 400px;
}

.service .section-2 {
    padding: 100px 0px 100px;
    background-color: #00597B;
    color: #fff;
    position: relative;
}

.service .section-2 h1 {
    position: relative;
}

.service .section-2 .astrik {
    height: 50px;
    width: 50px;
    position: absolute;
    right: 0;
    top: -15px;
}

.service .section-2 h4 {
    font-weight: 400;
}

.service .section-2 .services h3 {
    font-weight: 400;
    margin-bottom: 20px;
}

.service .section-3 {
    padding: 100px 0px 0px;
}

.service .section-3 .card {
    border-top: 0;
}

.service .section-4 {
    padding: 100px 0 0px 0;
}

/* .service .section-4 img {
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
	clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
} */

.service .section-5 {
    padding: 100px 0px 0px;
}

.service .section-5 .card {
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    transition: all .5s;
}

.service .section-6 .video-player {
    background-color: gray;
    height: 574px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
}

@media only screen and (max-width: 992px) {
    .service .section-5 .card {
        border: 0;
        background-color: transparent;
        border-radius: 0;
        transition: all .5s;
    }
}

@media only screen and (max-width: 768px) {
    .service .section-1 {
        height: auto;
        padding: 100px 0 50px;
        color: #00597B;
    }
    
    .technology .section-1 svg {
        height: 300px;
        width: 300px;
    }

    .service .section-2
    {
        padding: 60px 0 60px;
    }

    .service .section-3 {
        padding: 60px 0 30px;
    }

    .service .section-2 .astrik {
        top: auto;
        bottom: -40px;
    }
    .service .section-4 {
        padding: 30px 0 0px 0;
    }
    .service .section-5 {
        padding: 40px 0px 40px;
    }
}

@media only screen and (max-width: 576px) {
    .service .section-1 {
        height: 100vh;
    }
}

@media only screen and (max-height: 768px) {
    .service .section-1 {
        height: auto;
        padding: 150px 0 100px;
    }
}


/* @media only screen and (max-width: 576px){
    .page-title {
        font-size: 18px;
    }
    .breadcrumb a {
        font-size: 12px;
    }
    .breadcrumb .breadcrumb-item.active a{
        font-size: 12px;
    }
} */

/* ******* */
/* service */
/* ******* */


/* ****************** */
/* software-licencing */
/* ****************** */

.software-licencing {
    margin-top: 130px;
}

.software-licencing .card {
    border: none;
}

.software-licencing .image-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* aspect-ratio: 1/1; */
    object-fit: cover;
}

.software-licencing img {
    aspect-ratio: 1/1;
    object-fit: contain;
    width: 110px;
    margin-bottom: 50px;
}

.software-licencing img.wide-img {
    width: 200px;
    margin-bottom: 0;
}

.software-licencing h5.card-text {
    text-align: center;
}

/* .software-licencing .img-card-top {
    aspect-ratio: 1/1;
} */

/* section - 4 */

/* .service.design .section-4 {
    width: 100vw;
    height: 110vh;
    display: flex;
    align-items: center;
    margin-top: 50px;
} */

.service.design .design-animation {
    width: 100%;
    object-fit: contain;
}

/* section - 4 */