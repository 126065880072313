.e-commerce .section-2 ul {
    padding-left: 15px;
}

.e-commerce .section-2 ul li {
    list-style: '> ';
    font-size: 18px;
}

.e-commerce .section-2 .col-lg-4 .d-flex {
    justify-content: space-between;
    align-items: center;
}

.e-commerce .section-2 .col-lg-4 .d-flex:first-child div.img-container:first-child {
    flex-basis: 70%;
    margin-right: 10px;
}

.e-commerce .section-2 .col-lg-4 .d-flex:first-child div.img-container:nth-child(2) {
    flex-basis: 30%;
}

.e-commerce .section-2 .col-lg-4 .d-flex:nth-child(2) div.img-container:first-child {
    flex-basis: 30%;
    margin-right: 10px;
}

.e-commerce .section-2 .col-lg-4 .d-flex:nth-child(2) div.img-container:nth-child(2) {
    flex-basis: 70%;
    /* margin-right: 10px; */
}

.e-commerce .section-2 .col-lg-4 div.img-container{
    background-color: #fff;
    border-radius: 5px;
    height: 100px;
    margin-top: 15px;
    padding: 10px 20px;
}

.e-commerce .section-2 .col-lg-4 div.img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
/* 
@media screen and (max-width: 771px) {
    .e-commerce .section-2 .col-12 {
        margin-left: 10%;
        width: 500px;
    }
}

@media screen and (min-width: 771px) {
    .e-commerce .section-2 .col-12 {
        width: 500px;
    }
} */

.e-commerce .section-3 .sub-section-1 .img-container,
.e-commerce .section-3 .sub-section-3 .img-container {
    width: 100%;
    height: 100px;
    background-color: #D9D9D9;
    padding: 15px;
    border-radius: 8px;
}

.e-commerce .section-3 .sub-section-3 .img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.e-commerce .section-3 .sub-section-2,
.e-commerce .section-3 .sub-section-3 {
    padding-top: 60px;
}

.e-commerce .section-3 p {
    font-size: 18px;
}

.e-commerce .section-3 .sub-section-2 .row.row-1 .col-12:first-child,
.e-commerce .section-3 .sub-section-2 .row.row-1 .col-12:nth-child(2),
.e-commerce .section-3 .sub-section-2 .row.row-3 .col-12:first-child,
.e-commerce .section-3 .sub-section-2 .row.row-3 .col-12:nth-child(2) {
    border-right: 1px solid black;
}

.e-commerce .section-3 .sub-section-2 .row.row-1 .col-12 {
    margin-bottom: 15px;
}

.e-commerce .section-3 .sub-section-2 .row.row-2 .col-12 hr {
    height: 1px;
    opacity: 0.7;
    background-color: #000;
}

.e-commerce .section-3 .sub-section-2 .row.row-2 .col-12:first-child hr,
.e-commerce .section-3 .sub-section-2 .row.row-2 .col-12:nth-child(2) hr {
    margin-right: 15px;
}

.e-commerce .section-3 .sub-section-2 .row.row-2 .col-12:nth-child(2) hr,
.e-commerce .section-3 .sub-section-2 .row.row-2 .col-12:nth-child(3) hr {
    margin-left: 15px;
}

.e-commerce .section-3 .sub-section-2 .row.row-1 .col-12:nth-child(2) p,
.e-commerce .section-3 .sub-section-2 .row.row-1 .col-12:nth-child(3) p,
.e-commerce .section-3 .sub-section-2 .row.row-3 .col-12:nth-child(2) p,
.e-commerce .section-3 .sub-section-2 .row.row-3 .col-12:nth-child(3) p {
    padding-left: 20px;
}

.e-commerce .section-3 .sub-section-2 .row.row-3 .col-12 {
    margin-top: 15px;
}


/* deep */
.e-commerce .section-3 .available-modules .card {
    border: 0;
    background-color: #00597B;
    color: #fff;
    border-radius: 8px;
    padding: 20px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.e-commerce .section-3 .available-modules .card .card-body{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}