@-webkit-keyframes animate-business-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-1 {
  -webkit-animation: animate-business-fill-1 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.2s both;
  animation: animate-business-fill-1 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    1.2s both;
}

@-webkit-keyframes animate-business-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-2 {
  -webkit-animation: animate-business-fill-2 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.3199999999999998s both;
  animation: animate-business-fill-2 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    1.3199999999999998s both;
}

@-webkit-keyframes animate-business-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-3 {
  -webkit-animation: animate-business-fill-3 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.44s both;
  animation: animate-business-fill-3 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    1.44s both;
}

@-webkit-keyframes animate-business-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-4 {
  -webkit-animation: animate-business-fill-4 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.56s both;
  animation: animate-business-fill-4 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    1.56s both;
}

@-webkit-keyframes animate-business-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-5 {
  -webkit-animation: animate-business-fill-5 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.68s both;
  animation: animate-business-fill-5 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    1.68s both;
}

@-webkit-keyframes animate-business-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-6 {
  -webkit-animation: animate-business-fill-6 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.7999999999999998s both;
  animation: animate-business-fill-6 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    1.7999999999999998s both;
}

@-webkit-keyframes animate-business-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-7 {
  -webkit-animation: animate-business-fill-7 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.92s both;
  animation: animate-business-fill-7 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    1.92s both;
}

@-webkit-keyframes animate-business-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-8 {
  -webkit-animation: animate-business-fill-8 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.04s both;
  animation: animate-business-fill-8 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    2.04s both;
}

@-webkit-keyframes animate-business-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-9 {
  -webkit-animation: animate-business-fill-9 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.16s both;
  animation: animate-business-fill-9 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    2.16s both;
}

@-webkit-keyframes animate-business-fill-10 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-10 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-10 {
  -webkit-animation: animate-business-fill-10 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.2800000000000002s both;
  animation: animate-business-fill-10 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.2800000000000002s both;
}

@-webkit-keyframes animate-business-fill-11 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-11 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-11 {
  -webkit-animation: animate-business-fill-11 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.4s both;
  animation: animate-business-fill-11 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.4s both;
}

@-webkit-keyframes animate-business-fill-12 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-12 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-12 {
  -webkit-animation: animate-business-fill-12 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.5199999999999996s both;
  animation: animate-business-fill-12 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.5199999999999996s both;
}

@-webkit-keyframes animate-business-fill-13 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-13 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-13 {
  -webkit-animation: animate-business-fill-13 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.6399999999999997s both;
  animation: animate-business-fill-13 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.6399999999999997s both;
}

@-webkit-keyframes animate-business-fill-14 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-14 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-14 {
  -webkit-animation: animate-business-fill-14 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.76s both;
  animation: animate-business-fill-14 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.76s both;
}

@-webkit-keyframes animate-business-fill-15 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-15 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-15 {
  -webkit-animation: animate-business-fill-15 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.88s both;
  animation: animate-business-fill-15 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.88s both;
}

@-webkit-keyframes animate-business-fill-16 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-16 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-16 {
  -webkit-animation: animate-business-fill-16 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3s both;
  animation: animate-business-fill-16 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3s both;
}

@-webkit-keyframes animate-business-fill-17 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-17 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-17 {
  -webkit-animation: animate-business-fill-17 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.12s both;
  animation: animate-business-fill-17 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.12s both;
}

@-webkit-keyframes animate-business-fill-18 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-18 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-18 {
  -webkit-animation: animate-business-fill-18 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.24s both;
  animation: animate-business-fill-18 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.24s both;
}

@-webkit-keyframes animate-business-fill-19 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-19 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-19 {
  -webkit-animation: animate-business-fill-19 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.3600000000000003s both;
  animation: animate-business-fill-19 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.3600000000000003s both;
}

@-webkit-keyframes animate-business-fill-20 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-20 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-20 {
  -webkit-animation: animate-business-fill-20 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.4799999999999995s both;
  animation: animate-business-fill-20 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.4799999999999995s both;
}

@-webkit-keyframes animate-business-fill-21 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-21 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-21 {
  -webkit-animation: animate-business-fill-21 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.5999999999999996s both;
  animation: animate-business-fill-21 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.5999999999999996s both;
}

@-webkit-keyframes animate-business-fill-22 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-22 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-22 {
  -webkit-animation: animate-business-fill-22 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.7199999999999998s both;
  animation: animate-business-fill-22 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.7199999999999998s both;
}

@-webkit-keyframes animate-business-fill-23 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-23 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-23 {
  -webkit-animation: animate-business-fill-23 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.84s both;
  animation: animate-business-fill-23 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.84s both;
}

@-webkit-keyframes animate-business-fill-24 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-24 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-24 {
  -webkit-animation: animate-business-fill-24 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.96s both;
  animation: animate-business-fill-24 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.96s both;
}

@-webkit-keyframes animate-business-fill-25 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-25 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-25 {
  -webkit-animation: animate-business-fill-25 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.08s both;
  animation: animate-business-fill-25 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.08s both;
}

@-webkit-keyframes animate-business-fill-26 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-26 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-26 {
  -webkit-animation: animate-business-fill-26 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.2s both;
  animation: animate-business-fill-26 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.2s both;
}

@-webkit-keyframes animate-business-fill-27 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-27 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-27 {
  -webkit-animation: animate-business-fill-27 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.32s both;
  animation: animate-business-fill-27 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.32s both;
}

@-webkit-keyframes animate-business-fill-28 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-28 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-28 {
  -webkit-animation: animate-business-fill-28 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.4399999999999995s both;
  animation: animate-business-fill-28 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.4399999999999995s both;
}

@-webkit-keyframes animate-business-fill-29 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-29 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-29 {
  -webkit-animation: animate-business-fill-29 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.56s both;
  animation: animate-business-fill-29 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.56s both;
}

@-webkit-keyframes animate-business-fill-30 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-30 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-30 {
  -webkit-animation: animate-business-fill-30 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.68s both;
  animation: animate-business-fill-30 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.68s both;
}

@-webkit-keyframes animate-business-fill-31 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-31 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-31 {
  -webkit-animation: animate-business-fill-31 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.8s both;
  animation: animate-business-fill-31 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.8s both;
}

@-webkit-keyframes animate-business-fill-32 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-32 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-32 {
  -webkit-animation: animate-business-fill-32 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.92s both;
  animation: animate-business-fill-32 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.92s both;
}

@-webkit-keyframes animate-business-fill-33 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-33 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-33 {
  -webkit-animation: animate-business-fill-33 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.04s both;
  animation: animate-business-fill-33 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.04s both;
}

@-webkit-keyframes animate-business-fill-34 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-34 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-34 {
  -webkit-animation: animate-business-fill-34 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.16s both;
  animation: animate-business-fill-34 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.16s both;
}

@-webkit-keyframes animate-business-fill-35 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-35 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-35 {
  -webkit-animation: animate-business-fill-35 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.28s both;
  animation: animate-business-fill-35 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.28s both;
}

@-webkit-keyframes animate-business-fill-36 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-36 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-36 {
  -webkit-animation: animate-business-fill-36 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.4s both;
  animation: animate-business-fill-36 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.4s both;
}

@-webkit-keyframes animate-business-fill-37 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-37 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-37 {
  -webkit-animation: animate-business-fill-37 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.5200000000000005s both;
  animation: animate-business-fill-37 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.5200000000000005s both;
}

@-webkit-keyframes animate-business-fill-38 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-38 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-38 {
  -webkit-animation: animate-business-fill-38 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.64s both;
  animation: animate-business-fill-38 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.64s both;
}

@-webkit-keyframes animate-business-fill-39 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-39 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-39 {
  -webkit-animation: animate-business-fill-39 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.76s both;
  animation: animate-business-fill-39 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.76s both;
}

@-webkit-keyframes animate-business-fill-40 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-40 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-40 {
  -webkit-animation: animate-business-fill-40 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.88s both;
  animation: animate-business-fill-40 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.88s both;
}

@-webkit-keyframes animate-business-fill-41 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-41 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-41 {
  -webkit-animation: animate-business-fill-41 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6s both;
  animation: animate-business-fill-41 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6s both;
}

@-webkit-keyframes animate-business-fill-42 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-42 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-42 {
  -webkit-animation: animate-business-fill-42 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.12s both;
  animation: animate-business-fill-42 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.12s both;
}

@-webkit-keyframes animate-business-fill-43 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-43 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-43 {
  -webkit-animation: animate-business-fill-43 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.24s both;
  animation: animate-business-fill-43 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.24s both;
}

@-webkit-keyframes animate-business-fill-44 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-44 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-44 {
  -webkit-animation: animate-business-fill-44 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.36s both;
  animation: animate-business-fill-44 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.36s both;
}

@-webkit-keyframes animate-business-fill-45 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-45 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-45 {
  -webkit-animation: animate-business-fill-45 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.4799999999999995s both;
  animation: animate-business-fill-45 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.4799999999999995s both;
}

@-webkit-keyframes animate-business-fill-46 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-46 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-46 {
  -webkit-animation: animate-business-fill-46 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.6s both;
  animation: animate-business-fill-46 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.6s both;
}

@-webkit-keyframes animate-business-fill-47 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-47 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-47 {
  -webkit-animation: animate-business-fill-47 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.72s both;
  animation: animate-business-fill-47 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.72s both;
}

@-webkit-keyframes animate-business-fill-48 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-48 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-48 {
  -webkit-animation: animate-business-fill-48 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.84s both;
  animation: animate-business-fill-48 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.84s both;
}

@-webkit-keyframes animate-business-fill-49 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-49 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-49 {
  -webkit-animation: animate-business-fill-49 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.96s both;
  animation: animate-business-fill-49 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.96s both;
}

@-webkit-keyframes animate-business-fill-50 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-50 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-50 {
  -webkit-animation: animate-business-fill-50 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.08s both;
  animation: animate-business-fill-50 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.08s both;
}

@-webkit-keyframes animate-business-fill-51 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-51 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-51 {
  -webkit-animation: animate-business-fill-51 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.2s both;
  animation: animate-business-fill-51 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.2s both;
}

@-webkit-keyframes animate-business-fill-52 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-52 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-52 {
  -webkit-animation: animate-business-fill-52 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.32s both;
  animation: animate-business-fill-52 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.32s both;
}

@-webkit-keyframes animate-business-fill-53 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-53 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-53 {
  -webkit-animation: animate-business-fill-53 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.44s both;
  animation: animate-business-fill-53 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.44s both;
}

@-webkit-keyframes animate-business-fill-54 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-54 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-54 {
  -webkit-animation: animate-business-fill-54 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.56s both;
  animation: animate-business-fill-54 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.56s both;
}

@-webkit-keyframes animate-business-fill-55 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-55 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-55 {
  -webkit-animation: animate-business-fill-55 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.68s both;
  animation: animate-business-fill-55 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.68s both;
}

@-webkit-keyframes animate-business-fill-56 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

@keyframes animate-business-fill-56 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 196, 143);
  }
}

.bussiness-svg-56 {
  -webkit-animation: animate-business-fill-56 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.8s both;
  animation: animate-business-fill-56 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.8s both;
}

@-webkit-keyframes animate-business-fill-57 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-57 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-57 {
  -webkit-animation: animate-business-fill-57 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.92s both;
  animation: animate-business-fill-57 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.92s both;
}

@-webkit-keyframes animate-business-fill-58 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(252, 219, 197);
  }
}

@keyframes animate-business-fill-58 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(252, 219, 197);
  }
}

.bussiness-svg-58 {
  -webkit-animation: animate-business-fill-58 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.04s both;
  animation: animate-business-fill-58 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.04s both;
}

@-webkit-keyframes animate-business-fill-59 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(252, 219, 197);
  }
}

@keyframes animate-business-fill-59 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(252, 219, 197);
  }
}

.bussiness-svg-59 {
  -webkit-animation: animate-business-fill-59 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.16s both;
  animation: animate-business-fill-59 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.16s both;
}

@-webkit-keyframes animate-business-fill-60 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

@keyframes animate-business-fill-60 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(246, 167, 108);
  }
}

.bussiness-svg-60 {
  -webkit-animation: animate-business-fill-60 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.28s both;
  animation: animate-business-fill-60 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.28s both;
}

@-webkit-keyframes animate-business-fill-61 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-61 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-61 {
  -webkit-animation: animate-business-fill-61 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.399999999999999s both;
  animation: animate-business-fill-61 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.399999999999999s both;
}

@-webkit-keyframes animate-business-fill-62 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-business-fill-62 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.bussiness-svg-62 {
  -webkit-animation: animate-business-fill-62 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.52s both;
  animation: animate-business-fill-62 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.52s both;
}

@-webkit-keyframes animate-business-fill-63 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-63 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-63 {
  -webkit-animation: animate-business-fill-63 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.639999999999999s both;
  animation: animate-business-fill-63 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.639999999999999s both;
}

@-webkit-keyframes animate-business-fill-64 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-64 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-64 {
  -webkit-animation: animate-business-fill-64 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.76s both;
  animation: animate-business-fill-64 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.76s both;
}

@-webkit-keyframes animate-business-fill-65 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-65 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-65 {
  -webkit-animation: animate-business-fill-65 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.879999999999999s both;
  animation: animate-business-fill-65 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.879999999999999s both;
}

@-webkit-keyframes animate-business-fill-66 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-66 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-66 {
  -webkit-animation: animate-business-fill-66 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9s both;
  animation: animate-business-fill-66 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9s both;
}

@-webkit-keyframes animate-business-fill-67 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-67 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-67 {
  -webkit-animation: animate-business-fill-67 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.12s both;
  animation: animate-business-fill-67 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.12s both;
}

@-webkit-keyframes animate-business-fill-68 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-68 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-68 {
  -webkit-animation: animate-business-fill-68 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.239999999999998s both;
  animation: animate-business-fill-68 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.239999999999998s both;
}

@-webkit-keyframes animate-business-fill-69 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-69 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-69 {
  -webkit-animation: animate-business-fill-69 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.36s both;
  animation: animate-business-fill-69 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.36s both;
}

@-webkit-keyframes animate-business-fill-70 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-70 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-70 {
  -webkit-animation: animate-business-fill-70 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.479999999999999s both;
  animation: animate-business-fill-70 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.479999999999999s both;
}

@-webkit-keyframes animate-business-fill-71 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

@keyframes animate-business-fill-71 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(4, 90, 123);
  }
}

.bussiness-svg-71 {
  -webkit-animation: animate-business-fill-71 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.6s both;
  animation: animate-business-fill-71 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.6s both;
}

@-webkit-keyframes animate-business-fill-72 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-72 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-72 {
  -webkit-animation: animate-business-fill-72 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.719999999999999s both;
  animation: animate-business-fill-72 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.719999999999999s both;
}

@-webkit-keyframes animate-business-fill-73 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-73 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-73 {
  -webkit-animation: animate-business-fill-73 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.84s both;
  animation: animate-business-fill-73 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.84s both;
}

@-webkit-keyframes animate-business-fill-74 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-74 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-74 {
  -webkit-animation: animate-business-fill-74 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.959999999999999s both;
  animation: animate-business-fill-74 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 9.959999999999999s both;
}

@-webkit-keyframes animate-business-fill-75 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-75 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-75 {
  -webkit-animation: animate-business-fill-75 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.079999999999998s both;
  animation: animate-business-fill-75 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.079999999999998s both;
}

@-webkit-keyframes animate-business-fill-76 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-76 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-76 {
  -webkit-animation: animate-business-fill-76 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.2s both;
  animation: animate-business-fill-76 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.2s both;
}

@-webkit-keyframes animate-business-fill-77 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-77 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-77 {
  -webkit-animation: animate-business-fill-77 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.319999999999999s both;
  animation: animate-business-fill-77 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.319999999999999s both;
}

@-webkit-keyframes animate-business-fill-78 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-78 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-78 {
  -webkit-animation: animate-business-fill-78 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.44s both;
  animation: animate-business-fill-78 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.44s both;
}

@-webkit-keyframes animate-business-fill-79 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-79 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-79 {
  -webkit-animation: animate-business-fill-79 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.559999999999999s both;
  animation: animate-business-fill-79 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.559999999999999s both;
}

@-webkit-keyframes animate-business-fill-80 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-80 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-80 {
  -webkit-animation: animate-business-fill-80 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.68s both;
  animation: animate-business-fill-80 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.68s both;
}

@-webkit-keyframes animate-business-fill-81 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-81 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-81 {
  -webkit-animation: animate-business-fill-81 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.799999999999999s both;
  animation: animate-business-fill-81 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.799999999999999s both;
}

@-webkit-keyframes animate-business-fill-82 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-82 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-82 {
  -webkit-animation: animate-business-fill-82 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.919999999999998s both;
  animation: animate-business-fill-82 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 10.919999999999998s both;
}

@-webkit-keyframes animate-business-fill-83 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-83 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-83 {
  -webkit-animation: animate-business-fill-83 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.04s both;
  animation: animate-business-fill-83 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.04s both;
}

@-webkit-keyframes animate-business-fill-84 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-84 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-84 {
  -webkit-animation: animate-business-fill-84 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.159999999999998s both;
  animation: animate-business-fill-84 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.159999999999998s both;
}

@-webkit-keyframes animate-business-fill-85 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-85 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-85 {
  -webkit-animation: animate-business-fill-85 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.28s both;
  animation: animate-business-fill-85 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.28s both;
}

@-webkit-keyframes animate-business-fill-86 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-86 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-86 {
  -webkit-animation: animate-business-fill-86 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.399999999999999s both;
  animation: animate-business-fill-86 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.399999999999999s both;
}

@-webkit-keyframes animate-business-fill-87 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-87 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-87 {
  -webkit-animation: animate-business-fill-87 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.52s both;
  animation: animate-business-fill-87 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.52s both;
}

@-webkit-keyframes animate-business-fill-88 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-88 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-88 {
  -webkit-animation: animate-business-fill-88 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.639999999999999s both;
  animation: animate-business-fill-88 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.639999999999999s both;
}

@-webkit-keyframes animate-business-fill-89 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-89 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-89 {
  -webkit-animation: animate-business-fill-89 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.759999999999998s both;
  animation: animate-business-fill-89 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.759999999999998s both;
}

@-webkit-keyframes animate-business-fill-90 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

@keyframes animate-business-fill-90 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(243, 122, 32);
  }
}

.bussiness-svg-90 {
  -webkit-animation: animate-business-fill-90 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.879999999999999s both;
  animation: animate-business-fill-90 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.879999999999999s both;
}

@-webkit-keyframes animate-business-fill-91 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-business-fill-91 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.bussiness-svg-91 {
  -webkit-animation: animate-business-fill-91 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.999999999999998s both;
  animation: animate-business-fill-91 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) 11.999999999999998s both;
}
