/* section - 1 */
.about .section-1 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about .section-1 .about-title {
    font-weight: 700;
    font-size: 70px;
    color: #FF7A00;
    text-align: center;
}

.about .section-1 .about-title .text-blue {
    font-weight: 500;
}

@media only screen and (max-height: 600px) {
    .about .section-1 {
        height: auto;
        padding: 150px 0 0;
    }
}

@media only screen and (max-width: 1200px){
    .about .section-1 .about-title {
        font-size: 50px;
    }
}
@media only screen and (max-width: 992px){
    .about .section-1 .about-title {
        font-size: 40px;
    }
}
@media only screen and (max-width: 576px){
    .about .section-1 .about-title {
        font-size: 30px;
    }
}
/* section 1 */


/* section 2 */
.about .section-2 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about .astrik{
    height: 50px;
    width: 50px;
    position: absolute;
    right: -70px;
    top: -35px;
}

.about p {
    font-weight: 400;
    font-size: 24px;
}

@media only screen and (max-height: 600px) {
    .about .section-2 {
        height: auto;
        padding: 100px 0;
    }
}
/* section 2 */

/* section 3 */
.about .section-3 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about .vision {
    position: relative;
}

.about .vision .tab-content {
    position: relative;
    top: 160px;
    /* font-weight: 700; */
    /* font-size: 33px; */
    font-size: 24px;
}
.about .vision .tab-content .astrik {
    height: 3rem;
    width: 3rem;
    position: relative;
    right: 0%;
}

.about .vision .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: 33px;
    font-weight: 500;
    color: #D9D9D9;
    text-decoration: none;
    text-align: start;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.about .vision .nav-pills .nav-link.active {
    background-color: #fff;
    color: #FD7A0B;
    font-weight: 500;
    font-size: 110px;

}

@media only screen and (max-width: 1200px){
    .about .section-3 {
        height: auto;
        padding: 100px 0 0;
        background-color: #00597B;
        color: #fff;
    }
    /* .about .section-3 .astrik{
        height: 50px;
        width: 50px;
        position: absolute;
        right: -70px;
        top: -35px;
    } */
}
/* section 3 */

/* section - 4 */

.about .section-4 {
    padding-top: 60px;
    padding-top: 60px;
    display: flex;
    align-items: center;
}

.about .section-4 .team-members .card-1 img {
    width: 100%;
    height: auto;
    aspect-ratio: 3/4;
    object-fit: cover;
    /* transform: scale(0.8); */
    transition: all .3s ease-in-out;
}

.about .section-4 .team-members .card-1 .img-container {
    height: 60%;
    overflow: hidden;
    margin-bottom: 20px;
    transition: all .3s ease-in-out;
}

/* .about .section-4 .team-members .card-1 img:hover {
    transform: scale(1);
} */

.about .section-4 .team-members .card-1 .img-container:hover {
    height: 80%;
}

.about .section-4 .team-members .card-1 h5 {
    font-weight: 600;
    padding-left: 10px;
}

.about .section-4 .team-members .card-1 p{
    font-size: 16px;
    padding-left: 10px;
}

/* section - 4 */