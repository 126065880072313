.navbar-box-container {
  display: block;
  /* background-color: #00597B; */
  color: #fff;
}

.navbar-box-upper-container img {
  height: 42px;
  width: 42px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.navbar-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: black;
}

.navbar-box-upper-container {
  display: flex;
  align-items: center;
}

.navbar-box-upper-container h2 {
  margin: 0 0 0 10px;
  color: #00597b;
  font-size: 1.25rem;
  transition: color 0.05s linear;
}

.navbar-box-upper-container h2:hover {
  color: #fd7a0b;
}

.navbar-img-container img {
  object-fit: contain;
  aspect-ratio: 1/1;
}

.navbar-box-content {
  margin-left: 34px;
}

.list-navbar {
  display: flex;
  flex-direction: column;
  /* padding-top: 15px; */
  padding-bottom: 10px;
}

.navbar-box-content li {
  list-style-type: none;
  border: 0;
  font-size: 14px;
  /* background-color: #00597B; */
  padding-bottom: 10px;
  font-weight: 300;
  color: #000;
  transition: color 0.05s linear;
}

/* .navbar-box-content li:before {
    content: '>';
    margin-right: 0.5em;
} */

.navbar-box-content li::marker {
  content: " > ";
  font-size: 1.2em;
}

.navbar-box-content li:hover {
  color: #fd7a0b;
}

.navbar-box-content li a {
  padding: 0;
  /* font-weight: 400; */
}

/* accordion for mobile devices */
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #fff;
  box-shadow: none;
}

.accordion-button {
  padding-top: 0;
  padding-bottom: 0;
}

.accordion-button:focus {
  z-index: 3;
  border: 0;
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon) !important;
}

.accordion-flush .accordion-item {
  margin-bottom: 15px;
}

.accordion-flush .accordion-item:last-child {
  margin-bottom: 0;
}

/* responsive */

@media only screen and (max-width: 1200px) {
  .navbar-box-content {
    margin-left: 13px;
  }

  .navbar-box-upper-container h2 {
    font-weight: 400;
    font-size: 16px;
  }

  .navbar-box-upper-container img {
    /* display: none; */
    width: 22px;
    height: 22px;
  }
}
