:root {
  --bs-breadcrumb-divider: "•";
  --bs-navbar-toggler-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

:root {
  --swiper-image-ratio: 33.3%;
  /* ratio 16:9 = 56.25% */

  --swiper-width: 50%;
  --swiper-inactive-scale: 0.85;
  /* makes the other slide smaller */

  /* responsive settings */
  --swiper-mobile-width: 90%;
  --swiper-mobile-inactive-scale: 0.95;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.astrik {
  animation: rotateStar 10s linear infinite;
  animation-play-state: running;
}

.rotate-star:hover {
  animation-play-state: running;
}

@keyframes rotateStar {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

.col-15 {
  width: 20%;
  flex: 0 0 auto;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    flex: 0 0 auto;
  }
}

@media (max-width: 950px) {
  .section-7 .clients-list .row .col-2 {
    width: 34%;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    flex: 0 0 auto;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    flex: 0 0 auto;
  }
}

body {
  margin: 0rem;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

a {
  color: #979797;
  text-decoration: none;
  transition: all 0.4s;
}

a:hover {
  color: var(--bs-breadcrumb-item-active-color);
  text-decoration: none;
}

p {
  font-size: 14px !important;
}

.text-orange {
  color: #fd7a0b !important;
}

.bg-orange {
  background-color: #ff7a00;
}

.text-blue {
  color: #00597b !important;
}

.fw-400 {
  font-weight: 400;
}

.service-section-title {
  color: #ff7a00;
  font-weight: 400;
  text-transform: uppercase;
}

.card {
  border-radius: 0;
}

.animated-button {
  width: fit-content;
}

.animated-button div {
  background-color: #d9d9d9;
  color: #00597b;
}

.animated-button .button-text {
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  padding: 1.8rem 3rem;
  border-radius: 9999px;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-4rem);
  /* z-index: 1; */
}

.animated-button:hover .button-text {
  transform: translateX(0);
}

/* @media only screen and (max-width: 576px) {
    .animated-button .button-text {
        transition: transform .3s cubic-bezier(.77,0,.175,1);
        padding: 1.8rem 3rem;
        border-radius: 9999px;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-4rem);
        z-index: 1;
    }
} */

.animated-button .arrowRightBlue {
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.animated-button:hover .arrowRightBlue-left {
  animation: arrowMove 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.3s;
}

@keyframes arrowMove {
  0% {
    transform: translateX(0);
  }

  15% {
    transform: translateX(5px);
  }

  30% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(3px);
  }

  60% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0);
  }
}

.animated-button .arrowRightBlue-right {
  transform: translateX(-4rem);
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 1;
}

.animated-button:hover .arrowRightBlue-right {
  transform: translateX(-7rem) scale(0.5);
  opacity: 0;
}

/* header */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: transparent;
  color: #00597b;
  z-index: 999;
  transition: all 0.5s;
}

.navbar .brand_logo {
  width: 140px;
  height: 50px;
}

.navbar a.nav-link {
  text-decoration: none !important;
  color: #00597b;
}

.navbar a.nav-link.active {
  text-decoration: none !important;
  color: #00597b;
  /* background-color: #FD7A0B; */
  border-bottom: 2.5px #fd7a0b solid;
  /* border-radius: 4px; */
  width: fit-content;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:hover {
  border: none !important;
}

.navbar-toggler:focus {
  border: none !important;
  box-shadow: none !important;
}

.home-header a.nav-link {
  text-decoration: none !important;
  color: #fff;
}

.home-header a.nav-link.active {
  text-decoration: none !important;
  color: #fff;
  background-color: #fd7a0b;
  border-radius: 4px;
}

.home-header .show a.nav-link.active {
  text-decoration: none !important;
  color: #fff;
  background-color: #fd7a0b;
  border-radius: 4px;
}

.home-header .show .dropdown .nav-link {
  color: #00597b;
}

.home-header .show a.nav-link {
  color: #00597b;
}

.home-header .navbar-toggler-icon {
  background-image: var(--bs-navbar-toggler-icon);
}

.is-sticky {
  -webkit-animation: fadeInDown 0.5s ease-in-out 0s 1 normal none running;
  animation: fadeInDown 0.5s ease-in-out 0s 1 normal none running;
  background-color: #fff !important;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  color: #212c43 !important;
  height: 100px;
  left: 0;
  /* padding: 25px 50px; */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 15;
}

.is-sticky a.nav-link {
  color: #00597b;
}

/* .offcanvas img.img-fluid {
    width: 60%;
} */

.offset-navbar-img {
  height: 60px !important;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}

.dropdown {
  position: initial;
}

.dropdown-menu.show {
  display: flex;
}

.dropdown-menu[data-bs-popper] {
  top: 98px;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
  width: 100%;
}

.dropdown .nav-link {
  color: #00597b;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #00597b;
}

.home-header .dropdown .nav-link {
  color: #fff;
}

.dropdown-item {
  color: #00597b;
  transition: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-item img.img-fluid {
  width: auto;
  margin-right: 25px;
}

.dropdown-item.active {
  text-decoration: none !important;
  color: #00597b;
  border-bottom: 2.5px #fd7a0b solid;
  width: fit-content;
  background-color: #fff;
}

.dropdown-item:active {
  text-decoration: none !important;
  color: #00597b;
  border-bottom: 2.5px #fd7a0b solid;
  width: fit-content;
  background-color: #fff;
}

/* .dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    background-color: #00597B;
} */

@media only screen and (max-width: 768px) {
  .navbar img {
    height: 40px;
  }

  .offcanvas-header img {
    height: auto;
  }

  .dropdown-item {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1200px) {
  .dropdown-menu {
    border: none !important;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    height: 90vh;
    overflow: scroll;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .dropdown:hover > .dropdown-menu {
    /* display: block; */
    margin-top: var(--bs-dropdown-spacer);
    left: 0;
    top: 98px;
    width: 100%;
    display: flex;
    border: 1px #d9d9d9 solid;
  }

  .navbar .nav-item {
    padding: 40px 0;
    margin: 0 10px;
  }

  .dropdown-item {
    /* color: #00597B;
        transition: none; */
    color: #fff;
    transition: none;
    background: #00597b;
    border-radius: 5px;
    padding: 20px 25px;
    text-align: center;
  }

  .dropdown-item.active {
    color: #fff;
    text-decoration: none;
    background-color: #fd7a0b;
    border-bottom: 0;
    width: 100%;
  }

  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #fd7a0b;
    border-bottom: 0;
    width: 100%;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #fff;
    background-color: #00597b;
  }

  .dropdown-item.active:focus,
  .dropdown-item.active:hover {
    color: #fff;
    background-color: #fd7a0b;
  }
}

.hide-navbar {
  display: none;
}

/* header */

/* section - 1 */

.home .section-1 {
  background-color: #00597b;
  color: #fff;
  /* backdrop-filter: blur(12vmax); */
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* cursor: none; */
}

.home .hero-text {
  /* max-width: 400px; */
  /* margin: auto; */
  /* font-size: 46px; */
  font-weight: 400;
}

.home .hero-text h1 {
  font-size: 3rem;
  font-weight: 400;
}

.home .hero-svg {
  position: absolute;
  bottom: 0;
  left: 0;
  /* height: 402px;
    width: 641px; */
}

.home .section-1 svg {
  width: 641px;
}

@media only screen and (max-width: 992px) {
  /* .home .section-1 {
        height: auto;
        padding-top: 60px;
    } */
  .home .hero-text {
    font-size: 40px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 768px) {
  .home .hero-text {
    font-size: 40px;
    font-weight: 400;
  }

  .home .hero-svg {
    width: 500px;
  }
}

@media only screen and (max-width: 576px) {
  /* .home .section-1 {
        height: 55.5rem;
        padding-top: 60px;
    } */
  .home .hero-text {
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 150px;
  }

  .home .hero-text h1 {
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 150px;
  }

  .home .hero-svg {
    width: auto;
  }
}

@media only screen and (max-height: 654px) {
  .home .section-1 {
    height: auto;
    padding-top: 60px;
  }

  .home .hero-text {
    margin: 150px 0 350px;
  }

  .home .hero-svg {
    width: auto;
  }
}

.hero-arrow {
  opacity: 0;
  stroke: #fff;
  fill: transparent;
  stroke-width: 1;
  stroke-dasharray: 387px;
  stroke-dashoffset: 999px;
  animation: hero-arrow 1.5s linear 1s alternate-reverse infinite;
}

@keyframes hero-arrow {
  from {
    opacity: 0;
    stroke-dashoffset: 420px;
  }

  to {
    stroke-dashoffset: 210px;
    opacity: 1;
  }
}

.stats:nth-child(1) {
  /* opacity: 0; */
  animation: stats 1s cubic-bezier(0.65, 0.05, 0.36, 1) alternate infinite;
  transform-origin: bottom;
}

.stats:nth-child(3) {
  /* opacity: 0; */
  animation: stats 1s cubic-bezier(0.65, 0.05, 0.36, 1) 0.5s alternate infinite;
  transform-origin: bottom;
}

.stats:nth-child(2) {
  /* opacity: 0; */
  animation: stats 1s cubic-bezier(0.65, 0.05, 0.36, 1) 1s alternate infinite;
  transform-origin: bottom;
}

@keyframes stats {
  from {
    /* transform: translateY(100%); */
    transform: scale(0);
  }

  to {
    transform: scale(1);
    /* transform: translateY(0); */
    /* opacity: 1; */
  }
}

.bulb {
  animation: glow 5s linear infinite;
}

@keyframes glow {
  0% {
    fill: #fff;
    stroke: #fff;
  }

  50% {
    fill: #fd7a0b;
    stroke: #fd7a0b;
  }

  100% {
    fill: #fff;
    stroke: #fff;
  }
}

/* section - 1 */

/* section - 2 */
.home .section-2 {
  /* padding-top: 100px; */
  /* padding-bottom: 100px; */
  /* height: 100vh; */
  position: relative;
}

.home .section-2 .video-player iframe {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  margin-top: -168px;
  border-radius: 24px;
}

iframe video {
  display: block !important;
  margin: 0 !important;
  padding: 0 !important;
  object-fit: fill !important;
}

.home .section-2 .video-player iframe body video {
  object-fit: cover !important;
}

.home .video-player {
  background-color: #d9d9d9;
  height: 600px;
  /* max-width: 1113px; */
  /* margin: auto; */
  margin: 0 80px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  position: relative;
  top: -75px;
}

@media screen and (max-width: 991px) and (min-width: 500px) {
  .home .video-player {
    height: 450px;
  }

  .video-player .video {
    border-radius: 0px !important;
  }
}

@media screen and (max-width: 500px) {
  .home .video-player {
    height: 200px;
  }
}

.home .video-player .scroll-down-arrow-container {
  position: relative;
  top: -63.5px;
  left: 50px;
  width: 38px;
  height: 167px;
  background-color: #fff;
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.home .video-player .scroll-down-arrow {
  animation: scrollDown 1.5s ease-in-out infinite;
}

@keyframes scrollDown {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 0;
  }
}

@media only screen and (max-width: 992px) {
  .home .video-player {
    border-radius: 0;
    margin: auto;
    top: 0;
  }

  .home .video-player .scroll-down-arrow-container {
    top: -50px;
    height: 100px;
    width: 30px;
    padding: 10px;
  }

  /* .home .video-player .scroll-down-arrow {
        width: 14px;
        height: 62px;
    } */
}

/* section - 2 */

/* section - 3 */
.home .section-3 {
  /* padding-top: 100px;
    padding-bottom: 100px; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: none;
}

.home .section-3 span::selection {
  background-color: transparent;
}

@media (pointer: fine) {
  .cursor {
    display: block;
  }
}

@media screen and (max-width: 991px) and (min-width: 500px) {
  .home .section-3 {
    height: 50vh;
  }
}

@media screen and (max-width: 500px) {
  .home .astrik {
    height: 1.8rem !important;
    width: 2.8rem;
  }

  .home .section-3 {
    margin-top: 100px;
    margin-bottom: 40px;
    height: auto;
  }

  .home .section-3 .section-3-content {
    font-size: 22px !important;
  }
}

.cursor {
  width: 32px;
  height: 32px;
  background-color: #00597b;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 50%;
  transition: all 0.2s;
  /* transition: 150ms width cubic-bezier(0.39, 0.575, 0.565, 1),
        150ms height cubic-bezier(0.39, 0.575, 0.565, 1),
        150ms transform cubic-bezier(0.39, 0.575, 0.565, 1); */
  z-index: 1;
  will-change: transform;
  /* mix-blend-mode: color; */
}

.home .section-3:hover > .cursor {
  display: block;
}

.home .section-3 .section-3-content {
  color: #cbcbcb;
  font-size: 50px;
}

@media only screen and (max-width: 768px) {
  .home .section-3 .section-3-content {
    color: #fd7a0b;
    font-size: 30px;
  }
}

.home .section-3-content span:nth-child(1) {
  font-weight: 500;
}

.home .section-3-content span:nth-child(2) {
  font-weight: 700;
}

/* section - 3 */

/* section - 4 */
.home .section-4 {
  padding-top: 50px;
  /* height: 100vh; */
}

/* @media only screen and (max-height: 700px) {
    .home .section-4 {
        height: auto;
    }
} */

.home .scrolling-text {
  font-weight: 400;
}

/* .home .astrik:before {
    content: ' \002A';
    display: block;
    color: #FD7A0B;
} */
.home .astrik {
  height: 2.8rem;
  width: 2.8rem;
}

.home .home-cards .card {
  border-top: 0;
  border-bottom: 0;
  /* background-color: #D9D9D9; */
  border-radius: 0;
  transition: all 0.5s;
}

.home .home-cards .card .card-title {
  color: #979797;
}

.home .home-cards .card-bg {
  background-color: transparent;
}

.home .home-cards .card-bg-active .card-title {
  color: #00597b;
}

div.card-bg-active {
  background-color: #d9d9d9;
}

.home .home-cards .btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s;
}

.home .home-cards .card-text {
  color: #000;
  font-size: 14px;
  opacity: 0;
  transition: all 0.5s;
}

/* .section-4 .home-cards div.design p,
.section-4 .home-cards div.business p,
.section-4 .home-cards div.technology p {
    margin-top: 85px;
} */

.card-bg-active .card-title {
  color: #00597b;
}

.card-bg-active:hover .btn {
  opacity: 1;
  background-color: #00597b;
}

.card-text {
  opacity: 1;
}

/* .home .home-cards .card-bg:hover {
    background-color: #D9D9D9;
} */
.card-bg:hover div.card-bg-active {
  background-color: transparent !important;
}

.home .home-cards .card-bg:hover .card-title {
  color: #00597b;
}

.home .home-cards .card-bg:hover .btn {
  opacity: 1;
  background-color: #00597b;
}

.home .home-cards .card-text {
  opacity: 1;
}

.home .home-cards .card-bg-active .btn {
  opacity: 1;
  background-color: #00597b;
}

/* mobile */
.accordion-item {
  border: none;
}

.home .home-cards .accordion-button {
  color: #979797;
}

.home .home-cards .accordion-button:focus {
  z-index: 3;
  border: none;
  outline: 0;
  box-shadow: none;
}

.home .home-cards .accordion-button:not(.collapsed) {
  color: #00597b;
  background-color: #d9d9d9;
  box-shadow: none;
}

.home .home-cards .accordion-button:not(.collapsed) h2 {
  color: #00597b;
}

.home .home-cards .accordion-body {
  background-color: #d9d9d9;
}

.home .home-cards .accordion-body .btn {
  opacity: 1;
  background-color: #00597b;
  transition: all 0.5s;
}

.home .home-cards .accordion-body .card-text {
  color: #000;
  opacity: 1;
}

.accordion-button::after {
  display: none;
}

/* section - 4 */

/* section - 5 */

.home .section-5 {
  /* width: 100vw; */
  /* height: 110vh; */
  /* display: flex; */
  /* align-items: center; */
  margin-top: 100px;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .home .section-5 {
    margin-top: 60px;
  }

  .home-cards .accordion-flush .accordion-item {
    margin-bottom: 0px;
  }
}

/* section - 5 */

/* section - 7 */

.section-7 {
  margin-top: 60px;
  /* height: 80vh; */
  display: flex;
  align-items: center;
}

.section-7 .child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-7 .client-image {
  object-fit: contain;
  width: 100px;
  height: 100px;
}

.section-7 .client-gujarat-sahitya {
  padding: 20px;
}

.section-7 .client-vnv {
  padding: 5px;
}

.section-7 .client-chaap {
  padding: 10px;
}

/* .client-list-main {
    overflow: hidden;
}

.client-list {
    display: inline-flex;
    transform:translateX(100%);
    animation: cssmarquee 10s linear infinite;
}

.client-list .client-image-container {
    width: 100px;
    height: 100px;
    margin: 0 10px;
    aspect-ratio: 1/1;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-list .client-image-container .client-image {
    width: 100%;
}

.clients-list {
    overflow: hidden;
}

@keyframes cssmarquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
} */

.section-7 .clients-list .row {
  padding-top: 60px;
  margin: auto;
}

.section-7 .clients-list .row .col-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

@media screen and (max-width: 500px) {
  .section-7 .clients-list .row {
    padding-top: 30px;
  }
}

.section-7 .clients-list .row .col-2 {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding-top: 40px;
  padding-bottom: 50px;
  text-align: center;
}

.section-7 .clients-list .row .col-2:last-child {
  border-right: 1px solid black;
}

.section-7 .clients-list .row .col-2 img {
  width: 80%;
}

.section-7 .clients-list .row .col-2.gujarati-sahitya-logo img {
  width: 40% !important;
}

.section-7 .clients-list .row .col-2 p {
  color: #00597b;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  padding: 0px 35px 50px;
}

@media screen and (max-width: 992px) {
  .section-7 {
    /* margin-top: 80px; */
    margin-bottom: 50px;
  }

  .section-7 .clients-list .row {
    flex-wrap: nowrap;
    width: 100%;
    /* overflow: scroll; */
  }

  /* .section-7 .clients-list .row {
        transform: translateX(100%);
        animation: cssmarquee 15s linear infinite;
    } */

  .section-7 .clients-list .row .col-2 {
    border: 0;
  }

  .section-7 .clients-list .row .col-2:last-child {
    border: 0;
  }
}

/* section - 7 */

/* footer */
.footer {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
  z-index: 1;
}

.footer-blob {
  z-index: -1;
}

.footer-services p {
  color: #00597b;
}

.keep-in-touch {
  margin-bottom: 50px;
}

.footer img.bintech-logo {
  width: 40%;
}

.footer .social-links svg {
  display: block;
  width: 35px;
  height: 35px;
}

.footer .footnote {
  display: none;
}

@media only screen and (max-width: 768px) {
  .footer {
    height: 100vh;
    padding-top: 120px;
    background-color: #00597b;
    color: #fff;
    position: relative;
    /* display: flex;
        align-items: center; */
  }

  .footer-services p {
    color: #fff;
  }

  .footer .social-links a {
    color: #fff;
  }

  .footer .astrik {
    height: 40px;
    width: 40px;
  }

  .footer .footnote {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    font-size: 10px;
    margin-top: 50px;
  }
}

@media only screen and (max-height: 700px) {
  .footer {
    height: auto;
  }
}

.video-player {
  position: absolute;
}

video {
  object-fit: fill;
}

.video-player .video {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

@media screen and (max-width: 500px) {
  .video-player .video {
    border-radius: 0px;
  }
}

/* footer */
