 @-webkit-keyframes animate-software-stroke-1 {
    0% {
      stroke-dashoffset: 994.1475219726562px;
      stroke-dasharray: 994.1475219726562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 994.1475219726562px;
    }
  }
  
  @keyframes animate-software-stroke-1 {
    0% {
      stroke-dashoffset: 994.1475219726562px;
      stroke-dasharray: 994.1475219726562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 994.1475219726562px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-1 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  @keyframes animate-software-fill-1 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  .software-svg-1 {
    -webkit-animation: animate-software-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
                         animate-software-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
            animation: animate-software-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
                 animate-software-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
  }
  
  @-webkit-keyframes animate-software-stroke-2 {
    0% {
      stroke-dashoffset: 855.551513671875px;
      stroke-dasharray: 855.551513671875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 855.551513671875px;
    }
  }
  
  @keyframes animate-software-stroke-2 {
    0% {
      stroke-dashoffset: 855.551513671875px;
      stroke-dasharray: 855.551513671875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 855.551513671875px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-2 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  
  @keyframes animate-software-fill-2 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  
  .software-svg-2 {
    -webkit-animation: animate-software-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
                         animate-software-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
            animation: animate-software-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
                 animate-software-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
  }
  
  @-webkit-keyframes animate-software-stroke-3 {
    0% {
      stroke-dashoffset: 605.54150390625px;
      stroke-dasharray: 605.54150390625px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 605.54150390625px;
    }
  }
  
  @keyframes animate-software-stroke-3 {
    0% {
      stroke-dashoffset: 605.54150390625px;
      stroke-dasharray: 605.54150390625px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 605.54150390625px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-3 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  
  @keyframes animate-software-fill-3 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  
  .software-svg-3 {
    -webkit-animation: animate-software-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
                         animate-software-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7s both;
            animation: animate-software-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
                 animate-software-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7s both;
  }
  
  @-webkit-keyframes animate-software-stroke-4 {
    0% {
      stroke-dashoffset: 893.9940795898438px;
      stroke-dasharray: 893.9940795898438px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 893.9940795898438px;
    }
  }
  
  @keyframes animate-software-stroke-4 {
    0% {
      stroke-dashoffset: 893.9940795898438px;
      stroke-dasharray: 893.9940795898438px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 893.9940795898438px;
    }
  }
  
  .software-svg-4 {
    -webkit-animation: animate-software-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
                         animate-software-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
            animation: animate-software-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
                 animate-software-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
  }
  
  @-webkit-keyframes animate-software-stroke-5 {
    0% {
      stroke-dashoffset: 248.36111450195312px;
      stroke-dasharray: 248.36111450195312px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 248.36111450195312px;
    }
  }
  
  @keyframes animate-software-stroke-5 {
    0% {
      stroke-dashoffset: 248.36111450195312px;
      stroke-dasharray: 248.36111450195312px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 248.36111450195312px;
    }
  }
  
  .software-svg-5 {
    -webkit-animation: animate-software-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
                         animate-software-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9s both;
            animation: animate-software-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
                 animate-software-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9s both;
  }
  
  @-webkit-keyframes animate-software-stroke-6 {
    0% {
      stroke-dashoffset: 1177.158935546875px;
      stroke-dasharray: 1177.158935546875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1177.158935546875px;
    }
  }
  
  @keyframes animate-software-stroke-6 {
    0% {
      stroke-dashoffset: 1177.158935546875px;
      stroke-dasharray: 1177.158935546875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1177.158935546875px;
    }
  }
  
  .software-svg-6 {
    -webkit-animation: animate-software-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
                         animate-software-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
            animation: animate-software-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
                 animate-software-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
  }
  
  @-webkit-keyframes animate-software-stroke-7 {
    0% {
      stroke-dashoffset: 16.428339958190918px;
      stroke-dasharray: 16.428339958190918px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 16.428339958190918px;
    }
  }
  
  @keyframes animate-software-stroke-7 {
    0% {
      stroke-dashoffset: 16.428339958190918px;
      stroke-dasharray: 16.428339958190918px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 16.428339958190918px;
    }
  }
  
  .software-svg-7 {
    -webkit-animation: animate-software-stroke-7 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
                         animate-software-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
            animation: animate-software-stroke-7 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
                 animate-software-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
  }
  
  @-webkit-keyframes animate-software-stroke-8 {
    0% {
      stroke-dashoffset: 251.86001586914062px;
      stroke-dasharray: 251.86001586914062px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 251.86001586914062px;
    }
  }
  
  @keyframes animate-software-stroke-8 {
    0% {
      stroke-dashoffset: 251.86001586914062px;
      stroke-dasharray: 251.86001586914062px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 251.86001586914062px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-8 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-8 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-8 {
    -webkit-animation: animate-software-stroke-8 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
                         animate-software-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
            animation: animate-software-stroke-8 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
                 animate-software-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
  }
  
  @-webkit-keyframes animate-software-stroke-9 {
    0% {
      stroke-dashoffset: 251.86203002929688px;
      stroke-dasharray: 251.86203002929688px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 251.86203002929688px;
    }
  }
  
  @keyframes animate-software-stroke-9 {
    0% {
      stroke-dashoffset: 251.86203002929688px;
      stroke-dasharray: 251.86203002929688px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 251.86203002929688px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-9 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-9 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-9 {
    -webkit-animation: animate-software-stroke-9 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
                         animate-software-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
            animation: animate-software-stroke-9 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
                 animate-software-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
  }
  
  @-webkit-keyframes animate-software-stroke-10 {
    0% {
      stroke-dashoffset: 152.75201416015625px;
      stroke-dasharray: 152.75201416015625px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 152.75201416015625px;
    }
  }
  
  @keyframes animate-software-stroke-10 {
    0% {
      stroke-dashoffset: 152.75201416015625px;
      stroke-dasharray: 152.75201416015625px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 152.75201416015625px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-10 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-10 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-10 {
    -webkit-animation: animate-software-stroke-10 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
                         animate-software-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s both;
            animation: animate-software-stroke-10 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
                 animate-software-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s both;
  }
  
  @-webkit-keyframes animate-software-stroke-11 {
    0% {
      stroke-dashoffset: 280.2980041503906px;
      stroke-dasharray: 280.2980041503906px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 280.2980041503906px;
    }
  }
  
  @keyframes animate-software-stroke-11 {
    0% {
      stroke-dashoffset: 280.2980041503906px;
      stroke-dasharray: 280.2980041503906px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 280.2980041503906px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-11 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-11 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-11 {
    -webkit-animation: animate-software-stroke-11 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
                         animate-software-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
            animation: animate-software-stroke-11 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
                 animate-software-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
  }
  
  @-webkit-keyframes animate-software-stroke-12 {
    0% {
      stroke-dashoffset: 57.11004638671875px;
      stroke-dasharray: 57.11004638671875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 57.11004638671875px;
    }
  }
  
  @keyframes animate-software-stroke-12 {
    0% {
      stroke-dashoffset: 57.11004638671875px;
      stroke-dasharray: 57.11004638671875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 57.11004638671875px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-12 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-12 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-12 {
    -webkit-animation: animate-software-stroke-12 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
                         animate-software-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s both;
            animation: animate-software-stroke-12 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
                 animate-software-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s both;
  }
  
  @-webkit-keyframes animate-software-stroke-13 {
    0% {
      stroke-dashoffset: 37.39404296875px;
      stroke-dasharray: 37.39404296875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 37.39404296875px;
    }
  }
  
  @keyframes animate-software-stroke-13 {
    0% {
      stroke-dashoffset: 37.39404296875px;
      stroke-dasharray: 37.39404296875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 37.39404296875px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-13 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-13 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-13 {
    -webkit-animation: animate-software-stroke-13 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
                         animate-software-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s both;
            animation: animate-software-stroke-13 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
                 animate-software-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s both;
  }
  
  @-webkit-keyframes animate-software-stroke-14 {
    0% {
      stroke-dashoffset: 165.77401733398438px;
      stroke-dasharray: 165.77401733398438px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 165.77401733398438px;
    }
  }
  
  @keyframes animate-software-stroke-14 {
    0% {
      stroke-dashoffset: 165.77401733398438px;
      stroke-dasharray: 165.77401733398438px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 165.77401733398438px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-14 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-14 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-14 {
    -webkit-animation: animate-software-stroke-14 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
                         animate-software-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s both;
            animation: animate-software-stroke-14 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
                 animate-software-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s both;
  }
  
  @-webkit-keyframes animate-software-stroke-15 {
    0% {
      stroke-dashoffset: 38.60601806640625px;
      stroke-dasharray: 38.60601806640625px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 38.60601806640625px;
    }
  }
  
  @keyframes animate-software-stroke-15 {
    0% {
      stroke-dashoffset: 38.60601806640625px;
      stroke-dasharray: 38.60601806640625px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 38.60601806640625px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-15 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-15 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-15 {
    -webkit-animation: animate-software-stroke-15 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
                         animate-software-fill-15 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s both;
            animation: animate-software-stroke-15 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
                 animate-software-fill-15 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s both;
  }
  
  @-webkit-keyframes animate-software-stroke-16 {
    0% {
      stroke-dashoffset: 27.376007080078125px;
      stroke-dasharray: 27.376007080078125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 27.376007080078125px;
    }
  }
  
  @keyframes animate-software-stroke-16 {
    0% {
      stroke-dashoffset: 27.376007080078125px;
      stroke-dasharray: 27.376007080078125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 27.376007080078125px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-16 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  @keyframes animate-software-fill-16 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(253, 122, 11);
    }
  }
  
  .software-svg-16 {
    -webkit-animation: animate-software-stroke-16 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
                         animate-software-fill-16 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s both;
            animation: animate-software-stroke-16 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
                 animate-software-fill-16 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s both;
  }
  
  @-webkit-keyframes animate-software-stroke-17 {
    0% {
      stroke-dashoffset: 101.45401000976562px;
      stroke-dasharray: 101.45401000976562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 101.45401000976562px;
    }
  }
  
  @keyframes animate-software-stroke-17 {
    0% {
      stroke-dashoffset: 101.45401000976562px;
      stroke-dasharray: 101.45401000976562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 101.45401000976562px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-17 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-17 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-17 {
    -webkit-animation: animate-software-stroke-17 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
                         animate-software-fill-17 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1s both;
            animation: animate-software-stroke-17 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
                 animate-software-fill-17 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1s both;
  }
  
  @-webkit-keyframes animate-software-stroke-18 {
    0% {
      stroke-dashoffset: 65.02001953125px;
      stroke-dasharray: 65.02001953125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 65.02001953125px;
    }
  }
  
  @keyframes animate-software-stroke-18 {
    0% {
      stroke-dashoffset: 65.02001953125px;
      stroke-dasharray: 65.02001953125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 65.02001953125px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-18 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-18 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-18 {
    -webkit-animation: animate-software-stroke-18 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
                         animate-software-fill-18 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s both;
            animation: animate-software-stroke-18 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
                 animate-software-fill-18 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s both;
  }
  
  @-webkit-keyframes animate-software-stroke-19 {
    0% {
      stroke-dashoffset: 177.26400756835938px;
      stroke-dasharray: 177.26400756835938px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 177.26400756835938px;
    }
  }
  
  @keyframes animate-software-stroke-19 {
    0% {
      stroke-dashoffset: 177.26400756835938px;
      stroke-dasharray: 177.26400756835938px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 177.26400756835938px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-19 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-19 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-19 {
    -webkit-animation: animate-software-stroke-19 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s both,
                         animate-software-fill-19 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s both;
            animation: animate-software-stroke-19 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s both,
                 animate-software-fill-19 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s both;
  }
  
  @-webkit-keyframes animate-software-stroke-20 {
    0% {
      stroke-dashoffset: 109.25003051757812px;
      stroke-dasharray: 109.25003051757812px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 109.25003051757812px;
    }
  }
  
  @keyframes animate-software-stroke-20 {
    0% {
      stroke-dashoffset: 109.25003051757812px;
      stroke-dasharray: 109.25003051757812px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 109.25003051757812px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-20 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-20 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-20 {
    -webkit-animation: animate-software-stroke-20 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s both,
                         animate-software-fill-20 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s both;
            animation: animate-software-stroke-20 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s both,
                 animate-software-fill-20 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s both;
  }
  
  @-webkit-keyframes animate-software-stroke-21 {
    0% {
      stroke-dashoffset: 196.780029296875px;
      stroke-dasharray: 196.780029296875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 196.780029296875px;
    }
  }
  
  @keyframes animate-software-stroke-21 {
    0% {
      stroke-dashoffset: 196.780029296875px;
      stroke-dasharray: 196.780029296875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 196.780029296875px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-21 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-21 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-21 {
    -webkit-animation: animate-software-stroke-21 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s both,
                         animate-software-fill-21 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s both;
            animation: animate-software-stroke-21 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s both,
                 animate-software-fill-21 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s both;
  }
  
  @-webkit-keyframes animate-software-stroke-22 {
    0% {
      stroke-dashoffset: 43.5999755859375px;
      stroke-dasharray: 43.5999755859375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 43.5999755859375px;
    }
  }
  
  @keyframes animate-software-stroke-22 {
    0% {
      stroke-dashoffset: 43.5999755859375px;
      stroke-dasharray: 43.5999755859375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 43.5999755859375px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-22 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-22 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-22 {
    -webkit-animation: animate-software-stroke-22 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.52s both,
                         animate-software-fill-22 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6s both;
            animation: animate-software-stroke-22 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.52s both,
                 animate-software-fill-22 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6s both;
  }
  
  @-webkit-keyframes animate-software-stroke-23 {
    0% {
      stroke-dashoffset: 30.0880126953125px;
      stroke-dasharray: 30.0880126953125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 30.0880126953125px;
    }
  }
  
  @keyframes animate-software-stroke-23 {
    0% {
      stroke-dashoffset: 30.0880126953125px;
      stroke-dasharray: 30.0880126953125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 30.0880126953125px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-23 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-23 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-23 {
    -webkit-animation: animate-software-stroke-23 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6399999999999997s both,
                         animate-software-fill-23 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.7s both;
            animation: animate-software-stroke-23 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6399999999999997s both,
                 animate-software-fill-23 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.7s both;
  }
  
  @-webkit-keyframes animate-software-stroke-24 {
    0% {
      stroke-dashoffset: 134.47601318359375px;
      stroke-dasharray: 134.47601318359375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 134.47601318359375px;
    }
  }
  
  @keyframes animate-software-stroke-24 {
    0% {
      stroke-dashoffset: 134.47601318359375px;
      stroke-dasharray: 134.47601318359375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 134.47601318359375px;
    }
  }
  
  .software-svg-24 {
    -webkit-animation: animate-software-stroke-24 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.76s both,
                         animate-software-fill-24 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8000000000000003s both;
            animation: animate-software-stroke-24 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.76s both,
                 animate-software-fill-24 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8000000000000003s both;
  }
  
  @-webkit-keyframes animate-software-stroke-25 {
    0% {
      stroke-dashoffset: 760.3786010742188px;
      stroke-dasharray: 760.3786010742188px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 760.3786010742188px;
    }
  }
  
  @keyframes animate-software-stroke-25 {
    0% {
      stroke-dashoffset: 760.3786010742188px;
      stroke-dasharray: 760.3786010742188px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 760.3786010742188px;
    }
  }
  
  .software-svg-25 {
    -webkit-animation: animate-software-stroke-25 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.88s both,
                         animate-software-fill-25 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.9000000000000004s both;
            animation: animate-software-stroke-25 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.88s both,
                 animate-software-fill-25 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.9000000000000004s both;
  }
  
  @-webkit-keyframes animate-software-stroke-26 {
    0% {
      stroke-dashoffset: 289.4152526855469px;
      stroke-dasharray: 289.4152526855469px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 289.4152526855469px;
    }
  }
  
  @keyframes animate-software-stroke-26 {
    0% {
      stroke-dashoffset: 289.4152526855469px;
      stroke-dasharray: 289.4152526855469px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 289.4152526855469px;
    }
  }
  
  .software-svg-26 {
    -webkit-animation: animate-software-stroke-26 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3s both,
                         animate-software-fill-26 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4s both;
            animation: animate-software-stroke-26 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3s both,
                 animate-software-fill-26 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4s both;
  }
  
  @-webkit-keyframes animate-software-stroke-27 {
    0% {
      stroke-dashoffset: 359.642333984375px;
      stroke-dasharray: 359.642333984375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 359.642333984375px;
    }
  }
  
  @keyframes animate-software-stroke-27 {
    0% {
      stroke-dashoffset: 359.642333984375px;
      stroke-dasharray: 359.642333984375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 359.642333984375px;
    }
  }
  
  .software-svg-27 {
    -webkit-animation: animate-software-stroke-27 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.12s both,
                         animate-software-fill-27 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.1s both;
            animation: animate-software-stroke-27 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.12s both,
                 animate-software-fill-27 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.1s both;
  }
  
  @-webkit-keyframes animate-software-stroke-28 {
    0% {
      stroke-dashoffset: 438.79095458984375px;
      stroke-dasharray: 438.79095458984375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 438.79095458984375px;
    }
  }
  
  @keyframes animate-software-stroke-28 {
    0% {
      stroke-dashoffset: 438.79095458984375px;
      stroke-dasharray: 438.79095458984375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 438.79095458984375px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-28 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-28 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-28 {
    -webkit-animation: animate-software-stroke-28 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2399999999999998s both,
                         animate-software-fill-28 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s both;
            animation: animate-software-stroke-28 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2399999999999998s both,
                 animate-software-fill-28 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s both;
  }
  
  @-webkit-keyframes animate-software-stroke-29 {
    0% {
      stroke-dashoffset: 404.91192626953125px;
      stroke-dasharray: 404.91192626953125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 404.91192626953125px;
    }
  }
  
  @keyframes animate-software-stroke-29 {
    0% {
      stroke-dashoffset: 404.91192626953125px;
      stroke-dasharray: 404.91192626953125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 404.91192626953125px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-29 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  
  @keyframes animate-software-fill-29 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  
  .software-svg-29 {
    -webkit-animation: animate-software-stroke-29 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.36s both,
                         animate-software-fill-29 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.300000000000001s both;
            animation: animate-software-stroke-29 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.36s both,
                 animate-software-fill-29 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.300000000000001s both;
  }
  
  @-webkit-keyframes animate-software-stroke-30 {
    0% {
      stroke-dashoffset: 205.98648071289062px;
      stroke-dasharray: 205.98648071289062px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 205.98648071289062px;
    }
  }
  
  @keyframes animate-software-stroke-30 {
    0% {
      stroke-dashoffset: 205.98648071289062px;
      stroke-dasharray: 205.98648071289062px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 205.98648071289062px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-30 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-30 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-30 {
    -webkit-animation: animate-software-stroke-30 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.48s both,
                         animate-software-fill-30 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.4s both;
            animation: animate-software-stroke-30 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.48s both,
                 animate-software-fill-30 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.4s both;
  }
  
  @-webkit-keyframes animate-software-stroke-31 {
    0% {
      stroke-dashoffset: 20.788944244384766px;
      stroke-dasharray: 20.788944244384766px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 20.788944244384766px;
    }
  }
  
  @keyframes animate-software-stroke-31 {
    0% {
      stroke-dashoffset: 20.788944244384766px;
      stroke-dasharray: 20.788944244384766px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 20.788944244384766px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-31 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  @keyframes animate-software-fill-31 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  .software-svg-31 {
    -webkit-animation: animate-software-stroke-31 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5999999999999996s both,
                         animate-software-fill-31 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.5s both;
            animation: animate-software-stroke-31 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5999999999999996s both,
                 animate-software-fill-31 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.5s both;
  }
  
  @-webkit-keyframes animate-software-stroke-32 {
    0% {
      stroke-dashoffset: 20.776504516601562px;
      stroke-dasharray: 20.776504516601562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 20.776504516601562px;
    }
  }
  
  @keyframes animate-software-stroke-32 {
    0% {
      stroke-dashoffset: 20.776504516601562px;
      stroke-dasharray: 20.776504516601562px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 20.776504516601562px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-32 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  @keyframes animate-software-fill-32 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  .software-svg-32 {
    -webkit-animation: animate-software-stroke-32 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.7199999999999998s both,
                         animate-software-fill-32 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.6s both;
            animation: animate-software-stroke-32 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.7199999999999998s both,
                 animate-software-fill-32 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.6s both;
  }
  
  @-webkit-keyframes animate-software-stroke-33 {
    0% {
      stroke-dashoffset: 20.776933670043945px;
      stroke-dasharray: 20.776933670043945px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 20.776933670043945px;
    }
  }
  
  @keyframes animate-software-stroke-33 {
    0% {
      stroke-dashoffset: 20.776933670043945px;
      stroke-dasharray: 20.776933670043945px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 20.776933670043945px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-33 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  @keyframes animate-software-fill-33 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  .software-svg-33 {
    -webkit-animation: animate-software-stroke-33 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.84s both,
                         animate-software-fill-33 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.7s both;
            animation: animate-software-stroke-33 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.84s both,
                 animate-software-fill-33 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.7s both;
  }
  
  @-webkit-keyframes animate-software-stroke-34 {
    0% {
      stroke-dashoffset: 307.76043701171875px;
      stroke-dasharray: 307.76043701171875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 307.76043701171875px;
    }
  }
  
  @keyframes animate-software-stroke-34 {
    0% {
      stroke-dashoffset: 307.76043701171875px;
      stroke-dasharray: 307.76043701171875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 307.76043701171875px;
    }
  }
  
  .software-svg-34 {
    -webkit-animation: animate-software-stroke-34 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.96s both,
                         animate-software-fill-34 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.800000000000001s both;
            animation: animate-software-stroke-34 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.96s both,
                 animate-software-fill-34 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.800000000000001s both;
  }
  
  @-webkit-keyframes animate-software-stroke-35 {
    0% {
      stroke-dashoffset: 50.686649322509766px;
      stroke-dasharray: 50.686649322509766px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 50.686649322509766px;
    }
  }
  
  @keyframes animate-software-stroke-35 {
    0% {
      stroke-dashoffset: 50.686649322509766px;
      stroke-dasharray: 50.686649322509766px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 50.686649322509766px;
    }
  }
  
  .software-svg-35 {
    -webkit-animation: animate-software-stroke-35 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.08s both,
                         animate-software-fill-35 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.9s both;
            animation: animate-software-stroke-35 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.08s both,
                 animate-software-fill-35 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.9s both;
  }
  
  @-webkit-keyframes animate-software-stroke-36 {
    0% {
      stroke-dashoffset: 91.6219482421875px;
      stroke-dasharray: 91.6219482421875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.6219482421875px;
    }
  }
  
  @keyframes animate-software-stroke-36 {
    0% {
      stroke-dashoffset: 91.6219482421875px;
      stroke-dasharray: 91.6219482421875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.6219482421875px;
    }
  }
  
  .software-svg-36 {
    -webkit-animation: animate-software-stroke-36 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s both,
                         animate-software-fill-36 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5s both;
            animation: animate-software-stroke-36 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s both,
                 animate-software-fill-36 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5s both;
  }
  
  @-webkit-keyframes animate-software-stroke-37 {
    0% {
      stroke-dashoffset: 91.62188720703125px;
      stroke-dasharray: 91.62188720703125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.62188720703125px;
    }
  }
  
  @keyframes animate-software-stroke-37 {
    0% {
      stroke-dashoffset: 91.62188720703125px;
      stroke-dasharray: 91.62188720703125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.62188720703125px;
    }
  }
  
  .software-svg-37 {
    -webkit-animation: animate-software-stroke-37 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.32s both,
                         animate-software-fill-37 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.1s both;
            animation: animate-software-stroke-37 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.32s both,
                 animate-software-fill-37 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.1s both;
  }
  
  @-webkit-keyframes animate-software-stroke-38 {
    0% {
      stroke-dashoffset: 91.6219482421875px;
      stroke-dasharray: 91.6219482421875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.6219482421875px;
    }
  }
  
  @keyframes animate-software-stroke-38 {
    0% {
      stroke-dashoffset: 91.6219482421875px;
      stroke-dasharray: 91.6219482421875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.6219482421875px;
    }
  }
  
  .software-svg-38 {
    -webkit-animation: animate-software-stroke-38 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.4399999999999995s both,
                         animate-software-fill-38 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.2s both;
            animation: animate-software-stroke-38 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.4399999999999995s both,
                 animate-software-fill-38 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.2s both;
  }
  
  @-webkit-keyframes animate-software-stroke-39 {
    0% {
      stroke-dashoffset: 91.62396240234375px;
      stroke-dasharray: 91.62396240234375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.62396240234375px;
    }
  }
  
  @keyframes animate-software-stroke-39 {
    0% {
      stroke-dashoffset: 91.62396240234375px;
      stroke-dasharray: 91.62396240234375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.62396240234375px;
    }
  }
  
  .software-svg-39 {
    -webkit-animation: animate-software-stroke-39 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.56s both,
                         animate-software-fill-39 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.300000000000001s both;
            animation: animate-software-stroke-39 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.56s both,
                 animate-software-fill-39 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.300000000000001s both;
  }
  
  @-webkit-keyframes animate-software-stroke-40 {
    0% {
      stroke-dashoffset: 91.6239013671875px;
      stroke-dasharray: 91.6239013671875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.6239013671875px;
    }
  }
  
  @keyframes animate-software-stroke-40 {
    0% {
      stroke-dashoffset: 91.6239013671875px;
      stroke-dasharray: 91.6239013671875px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 91.6239013671875px;
    }
  }
  
  .software-svg-40 {
    -webkit-animation: animate-software-stroke-40 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.68s both,
                         animate-software-fill-40 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.4s both;
            animation: animate-software-stroke-40 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.68s both,
                 animate-software-fill-40 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.4s both;
  }
  
  @-webkit-keyframes animate-software-stroke-41 {
    0% {
      stroke-dashoffset: 260.4125671386719px;
      stroke-dasharray: 260.4125671386719px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 260.4125671386719px;
    }
  }
  
  @keyframes animate-software-stroke-41 {
    0% {
      stroke-dashoffset: 260.4125671386719px;
      stroke-dasharray: 260.4125671386719px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 260.4125671386719px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-41 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 89, 123);
    }
  }
  
  @keyframes animate-software-fill-41 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(0, 89, 123);
    }
  }
  
  .software-svg-41 {
    -webkit-animation: animate-software-stroke-41 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.8s both,
                         animate-software-fill-41 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.5s both;
            animation: animate-software-stroke-41 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.8s both,
                 animate-software-fill-41 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.5s both;
  }
  
  @-webkit-keyframes animate-software-stroke-42 {
    0% {
      stroke-dashoffset: 222.81719970703125px;
      stroke-dasharray: 222.81719970703125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 222.81719970703125px;
    }
  }
  
  @keyframes animate-software-stroke-42 {
    0% {
      stroke-dashoffset: 222.81719970703125px;
      stroke-dasharray: 222.81719970703125px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 222.81719970703125px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-42 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  
  @keyframes animate-software-fill-42 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  
  .software-svg-42 {
    -webkit-animation: animate-software-stroke-42 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.92s both,
                         animate-software-fill-42 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.6000000000000005s both;
            animation: animate-software-stroke-42 1s cubic-bezier(0.47, 0, 0.745, 0.715) 4.92s both,
                 animate-software-fill-42 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.6000000000000005s both;
  }
  
  @-webkit-keyframes animate-software-stroke-43 {
    0% {
      stroke-dashoffset: 13.158618927001953px;
      stroke-dasharray: 13.158618927001953px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 13.158618927001953px;
    }
  }
  
  @keyframes animate-software-stroke-43 {
    0% {
      stroke-dashoffset: 13.158618927001953px;
      stroke-dasharray: 13.158618927001953px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 13.158618927001953px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-43 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  @keyframes animate-software-fill-43 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  .software-svg-43 {
    -webkit-animation: animate-software-stroke-43 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.04s both,
                         animate-software-fill-43 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.7s both;
            animation: animate-software-stroke-43 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.04s both,
                 animate-software-fill-43 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.7s both;
  }
  
  @-webkit-keyframes animate-software-stroke-44 {
    0% {
      stroke-dashoffset: 13.15861988067627px;
      stroke-dasharray: 13.15861988067627px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 13.15861988067627px;
    }
  }
  
  @keyframes animate-software-stroke-44 {
    0% {
      stroke-dashoffset: 13.15861988067627px;
      stroke-dasharray: 13.15861988067627px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 13.15861988067627px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-44 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  @keyframes animate-software-fill-44 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  .software-svg-44 {
    -webkit-animation: animate-software-stroke-44 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.16s both,
                         animate-software-fill-44 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.8s both;
            animation: animate-software-stroke-44 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.16s both,
                 animate-software-fill-44 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.8s both;
  }
  
  @-webkit-keyframes animate-software-stroke-45 {
    0% {
      stroke-dashoffset: 13.158632278442383px;
      stroke-dasharray: 13.158632278442383px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 13.158632278442383px;
    }
  }
  
  @keyframes animate-software-stroke-45 {
    0% {
      stroke-dashoffset: 13.158632278442383px;
      stroke-dasharray: 13.158632278442383px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 13.158632278442383px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-45 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  @keyframes animate-software-fill-45 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(254, 199, 150);
    }
  }
  
  .software-svg-45 {
    -webkit-animation: animate-software-stroke-45 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.279999999999999s both,
                         animate-software-fill-45 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.9s both;
            animation: animate-software-stroke-45 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.279999999999999s both,
                 animate-software-fill-45 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.9s both;
  }
  
  @-webkit-keyframes animate-software-stroke-46 {
    0% {
      stroke-dashoffset: 59.04448699951172px;
      stroke-dasharray: 59.04448699951172px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 59.04448699951172px;
    }
  }
  
  @keyframes animate-software-stroke-46 {
    0% {
      stroke-dashoffset: 59.04448699951172px;
      stroke-dasharray: 59.04448699951172px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 59.04448699951172px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-46 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-46 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-46 {
    -webkit-animation: animate-software-stroke-46 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.3999999999999995s both,
                         animate-software-fill-46 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6s both;
            animation: animate-software-stroke-46 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.3999999999999995s both,
                 animate-software-fill-46 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6s both;
  }
  
  @-webkit-keyframes animate-software-stroke-47 {
    0% {
      stroke-dashoffset: 100.84239959716797px;
      stroke-dasharray: 100.84239959716797px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 100.84239959716797px;
    }
  }
  
  @keyframes animate-software-stroke-47 {
    0% {
      stroke-dashoffset: 100.84239959716797px;
      stroke-dasharray: 100.84239959716797px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 100.84239959716797px;
    }
  }
  
  @-webkit-keyframes animate-software-fill-47 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  @keyframes animate-software-fill-47 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(255, 122, 0);
    }
  }
  
  .software-svg-47 {
    -webkit-animation: animate-software-stroke-47 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.52s both,
                         animate-software-fill-47 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6.1000000000000005s both;
            animation: animate-software-stroke-47 1s cubic-bezier(0.47, 0, 0.745, 0.715) 5.52s both,
                 animate-software-fill-47 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6.1000000000000005s both;
  }
  