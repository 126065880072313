.contact-main {
    height: 100vh;
    overflow: scroll;
    background-color: #00597B;
}
.contact {
    height: 100vh;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    padding: 120px 0;
    /* background-color: #00597B; */
}

.contact .contact-title {
    font-weight: 700;
    letter-spacing: 0.1em;
    color: #fff;
}

.contact .astrik{
    height: 50px;
    width: 50px;
    position: absolute;
    right: 100px;
    top: -35px;
}

.contact .contact-info {
    background: #FF7A00;
    padding: 7rem 6rem 7rem 6rem;
}

.contact .contact-info .contact-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact .contact-info-item {
    display: flex;
    margin-bottom: 35px;
}

/* .contact .contact-info-icon {

} */

.contact .contact-info-item .contact-info-item-text {
    color: #fff;
}

.contact .contact-info-item .contact-info-address {
    max-width: 200px;
}


/* form */
.contact .col-7 .col-6 {
    /* float: left; */
    /* margin: 40px 3%; */
    position: relative;
}

.contact-card {
    max-width: 956px;
    margin: auto;
    background-color: #00597B;
    border-radius: 14px;
}

.title-container {
    position: relative;
}
.title-container h1 {
    opacity: 0.14;
}
.title-container .title-subtext {
    position: relative;
    bottom: 32px;
    left: 97px;
}

.contact-details {
    display: block;
    max-width: 600px;
}

.contact-card .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #00597B;
}

.contact-card .nav-pills .nav-link {
    background: #D9D9D9;
    border: 0;
    border-radius: 14px;
    color: #000;
}

.contact-card .nav-pills .nav-link-1 {
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
}

.contact-card .nav-pills .nav-link-2 {
    border-bottom-right-radius: 0;
    border-top-right-radius: 10px;
}

.contact-card .tab-content {
    border-radius: 14px;
}

.contact-card .tab-content img.text-us-side-image {
    width: 75%;
    height: auto;
    margin-top: 25%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    color: #fff;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
}

::placeholder {
    color: #fff;
}

:focus{outline: none;}

.effect-1 {
    border: 0;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;
}

.effect-1~.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #FF7A00;
    transition: 0.4s;
}

.effect-1:focus~.focus-border {
    width: 100%;
    transition: 0.4s;
}

img.contact-us-image {
    width: 100%;
    margin-top: 25%;
}

/* @media only screen and (max-height: 950px){
    .contact {
        height: auto;
    }
} */

@media only screen and (max-width: 992px){
    .title-container .title-subtext {
        position: relative;
        bottom: 25px;
        left: 20px;
    }
    .contact-card .nav-pills .nav-link-1 {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0px;
    }
    .contact-card .nav-pills .nav-link-2 {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0px;
    }
    .contact-card .tab-content {
        border-radius: 0px;
    }
    .contact-card {
        border-radius: 0px;
    }
}