.swiper-custom-navigation-btn {
    color: #000; 
    background: #D9D9D9;
    border-radius: 50%;
    width: 40px; 
    height: 40px; 
    display: flex; 
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}

.swiper-button-disabled {
    opacity: 0.4 !important;
    cursor: auto !important;
}

.swiper-custom-navigation-btn svg {
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
}

/* animated button - common */
.blog .animated-button {
    width: fit-content;
    cursor: pointer;
}

.blog .animated-button div {
    background-color: #FD7A0B;
    color: #fff;
}

.blog .animated-button svg {
    height: auto;
    width: auto;
}

.blog .animated-button .button-text {
    transition: transform .3s cubic-bezier(.77, 0, .175, 1);
    padding: 0 3rem;
    border-radius: 9999px;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-3rem);
    /* z-index: 1; */
}

.blog .animated-button:hover .button-text {
    transform: translateX(0);
}


.blog .animated-button .arrowRightBlue {
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    transition: transform .3s cubic-bezier(.77, 0, .175, 1);
}

.blog .animated-button .arrowRightBlue img {
    width: 50%;
}

.blog .animated-button:hover .arrowRightBlue-left {
    animation: arrowMove 2s cubic-bezier(.455, .03, .515, .955) .3s;
}

@keyframes arrowMove {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(5px);
    }

    30% {
        transform: translateX(0);
    }

    45% {
        transform: translateX(3px);
    }

    60% {
        transform: translateX(0);
    }

    75% {
        transform: translateX(2px);
    }

    100% {
        transform: translateX(0);
    }
}

.blog .animated-button .arrowRightBlue-right {
    transform: translateX(-3rem);
    transition: all .3s cubic-bezier(.77, 0, .175, 1);
    opacity: 1;
}

.blog .animated-button:hover .arrowRightBlue-right {
    transform: translateX(-7rem) scale(.5);
    opacity: 0;
}
/* animated button - common */

/* blob */
.blog-bob {
    position: absolute;
    bottom: -240px;
    right: 0;
    z-index: -1;
}
/* blob */

/* search anc category button */
.search-button, .category-button {
    transition: all .3s cubic-bezier(.77, 0, .175, 1);
    padding: 0 3rem;
    border-radius: 9999px;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px #FD7A0B solid;
}
.search-button {
    background: transparent;
    color: inherit;
}
.category-button {
    background: #FD7A0B;
    color: #fff;
}
/* search anc category button */

/* common card */
.blog .blog-section {
    position: relative;
}
.blog .blog-section .card {
    border-radius: 10px;
}

.blog .blog-section .card .card-img-overlay {
    /* background: rgba(0, 0, 0, 0.269); */
    background: rgba(0, 0, 0, 0.9);
}
/* common card */

/* animated button for blog cards */
/* .blog .blog-section .animated-button {
    position: absolute;
    bottom: 25px;
    left: 25px;
} */

.blog .blog-section .animated-button .button-text {
    padding: 0.5rem 1rem;
    height: 2rem;
    font-size: small;
    transform: translate(-2rem);
    background-color: #FD7A0B;
    color: #fff;
}

.blog .blog-section .animated-button .arrowRightBlue {
    height: 2rem;
    width: 2rem;
    background-color: #FD7A0B;
    color: #fff;
}
.blog .blog-section .blog-post-card-layout:hover .button-text {
    transform: translateX(0);
}
.blog .blog-section .animated-button .arrowRightBlue-right {
    transform: translateX(-2rem);
    transition: all .3s cubic-bezier(.77, 0, .175, 1);
    opacity: 1;
}

.blog .blog-post-card-layout:hover .arrowRightBlue-right {
    transform: translateX(-6rem) scale(.5);
    opacity: 0;
}
.blog .blog-post-card-layout:hover .arrowRightBlue-left {
    animation: arrowMove 2s cubic-bezier(.455, .03, .515, .955) .3s;
}
/* animated button for blog cards */

.blog .astrik {
    top: -50px !important;
    height: 50px;
    width: 50px;
    position: absolute;
    right: 0;
}

.blog .section-3, .blog .section-4, .blog .section-5, .blog .section-6 {
    padding: 100px 0;
    overflow: hidden;
}

/* section - 2 */
.blog .section-2 h1, .blog .section-3 h1, .blog .section-4 h1, .blog .section-4 h1, .blog .section-5 h1, .blog .section-6 h1 {
    width: fit-content;
    position: relative;
}

/* section - 2 */

/* section - 3 */
.blog .section-3 {
    border-bottom: 1px rgba(0, 0, 0, 0.5) solid;
    position: relative;
}
.blog .section-3 .custom-image {
    position: absolute;
    right: 0;
    bottom: -5px;
    opacity: 0.3;
    /* z-index: -1; */
}
/* section - 3 */

/* section - 4 */
.blog .section-4 {
    position: relative;
}
.blog .section-4 .custom-image {
    position: absolute;
    left: 0;
    bottom: -22px;
    /* z-index: -1; */
}
/* section - 4 */

/* section - 5 */
.blog .section-5 {
    background: #00597B;
    color: #fff;
    position: relative;
}
.blog .section-5 .custom-image {
    width: 150px;
    height: 313px;
    position: absolute;
    right: -82px;
    bottom: 92px;
}
/* section - 5 */

/* section - 5 */
.blog .section-6 {
    position: relative;
}
.blog .section-6 .custom-image {
    position: absolute;
    left: 0px;
    bottom: -45px;
    z-index: -1;
}
/* section - 6 */



/* blog-post-card-layout */
.blog-post-card-layout {
    border: 3px #00597B solid !important;
    border-radius: 10px !important;
    background: #fff;
    cursor: pointer;
}

.blog-post-card-layout .card-text {
    color: #FD7A0B;
    font-size: 12px !important;
    margin-bottom: 0;
}

.blog-post-card-layout .card-title {
    color: #00597B;
    margin-bottom: 1rem;
}
/* blog-post-card-layout */


@media screen and (max-width: 768px) {
    .swiper-custom-navigation-btn {
        width: 30px;
        height: 30px;
        padding: 8px;
    }
}