.blog-single-blob {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.swiper-button-prev-custom {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-140%, -50%);
}

.swiper-button-next-custom {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(140%, -50%);
}

/* blog-content */
html {
    scroll-padding-top: 200px !important;
}
.blog-single .blog-content .scrollspy-nav-container {
    position: sticky;
    top: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #fff;
}

.blog-single .blog-content .scrollspy-nav-container .scrollspy-nav-item {
    border-radius: 9999px;
    padding: 18px 0;
    border: 1px solid #FF7A00;
    background-color: #fff;
    display: flex;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blog-single .blog-content .scrollspy-nav-container .scrollspy-nav-item:hover {
    border-color: #00597B;
    color: #000;
}

.blog-single .blog-content .scrollspy-nav-container .scrollspy-active-title {
    border-color: #00597B;
    color: #000;
}

.blog-section-main {
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
}

@media screen and (max-width: 768px) {
    html {
        scroll-padding-top: 40% !important;
    }
    .blog-single .blog-content .scrollspy-nav-container .scrollspy-nav-item {
        padding: 5px 0;
    }
    .blog-single .blog-content .scrollspy-nav-container {
        padding-top: 30px;
        padding-bottom: 15px;
    }
}

@media screen and (max-height: 700px) {
    html {
        scroll-padding-top: 52% !important;
    }
}

@media screen and (max-width: 992px) {
    .blog-section-main {
        padding: 50px 0;
    }
}

/* #first {
    height: 90vh;
} */

.blog-single .blog-content .navbar {
    position: sticky;
    top: 120px;
    width: 100%;
    height: auto;
    background-color: transparent;
    color: #00597B;
    z-index: auto;
    transition: all 0.5s;
}

.blog-single .blog-content .blog-content-main {
    /* height: 100%; */
    margin-top: 0.5rem;
    overflow: auto;
}

.blog-single .blog-content .blog-content-section-1 {
    /* height: 90vh;
    padding-top: 200px; */
    /* padding: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-single .blog-content .blog-content-section-1 img {
    width: 80%;
    height: 80%;
}

/* reference */
.blog-single .reference {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0 0;
}