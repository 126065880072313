body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader {
  height: 100vh;
  width: 100%;
  /* background: #00597B; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 999; */
  transition: all 0.5s linear;
  /* animation: loader .3 alternate; */
}

.loader img {
  width: 100px;
}

/* @keyframes loader {
  from {opacity: 0;}
  to {opacity: 1;}
} */
