.service.technology .semi-bold {
  font-weight: 500;
}

/* section -2  */

.service.technology .section-2 .design-animation {
  width: 100px;
  height: 100px;
}

.service.technology .section-2 .animated-button {
  margin-left: 25px;
}

.service.technology .section-2 .animated-button:hover {
  margin-left: 0px;
}

.service.technology .section-2 .animated-button .button-text {
  padding: 0.3rem 1.6rem;
  height: 2.5rem;
}

.service.technology .section-2 .animated-button .arrowRightBlue {
  height: 2.5rem;
  width: 2.5rem;
}

.service.technology .section-2 .animated-button .arrowRightBlue img {
  width: 25px;
  height: auto;
}

@media only screen and (max-width: 576px) {
  .service.technology .section-2 .animated-button .button-text {
    font-size: 14px;
  }
}

/* section - 2 */

/* section - 3 */

.service.technology .section-3 .card {
  border-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .service.technology .section-3 .card {
    border: 0px !important;
  }
}

/* section - 3 */
